export const checkoutText = {
  photoIdHeader:
    "In order to verify your identity, please upload a picture of your government-issued photo ID and a full length selfie (best if taken in a full length mirror)",
  header: `It looks like <span class="nowrap">Slim Solution</span> may be right for you!`,
  body: "`Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.",
  footer: {
    title: "For verification purposes please upload the following photos:",
    id: "In order to verify your identity, please upload a picture of your government-issued photo ID",
    selfie: {
      main: "Please upload a full length selfie (best if taken in a full length mirror)",
      subText: `To ensure the highest quality of care during your telemedicine consultation, we kindly request your assistance in providing a full-body photo of yourself (please avoid wearing baggy clothes). 
The image should include your face to help facilitate verification of your identity. Please be assured that these images will remain private and will not be shared.
Our healthcare providers will use them solely for assessment purposes to better understand your health condition.`,
    },
  },
};

export const introPage = {
  header:
    "The following questions will help our partner physicians determine if you're eligible for medical weight loss management.",
  footer: "Beluga Health is a US licensed telemedicine provider.",
};

export const paymentSuccess = {
  header: "Thank you! Your information was submitted successfully.",
  body: "A doctor will now review your intake form. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.",
  footer: "Beluga Health is a US licensed telemedicine provider.",
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the Beluga Health medical team.",
};
